import Link from "next/link";
import React from "react";
import { isNewHomePageApplicable } from "../../../constants/feature";
import isMobile from "../../../helpers/isMobile";

const SpecialCategoryLayout = ({
  children = <></>,
  viewAll,
  productSize,
  rightSide,
  titleSection,
  currentTab,
  slug,
  extraDetails,
  className,
  isMostPopular,
  desc,
  toShop
}) => {
  let link = "/";
  if (isMostPopular) {
    link = "/top-sellers-hub"
  } else if (toShop) {
    link = '/shop/' + slug

  } else {
    link = viewAll;
    if (link && link !== '' && link.toLowerCase().indexOf('http://') == -1 && link.toLowerCase().indexOf('https://') == -1) {
      link = `https://${link}`;
    }
  }


  return (
    <div
      key={`special-category-${slug}`}
      className={` ${isNewHomePageApplicable ? 'special-container' : ''} ${className || ''} ${isMobile() ? `mt-3` : `mt-5`} ${rightSide ? 'right-side' : ''
        }`}
    >
      <div className="deals-caption-holder">
        <div className="deals-caption-new">
          <div className="deals-title">
            <h5>{titleSection}</h5>
          </div>

          {viewAll || toShop || true ? (
            <Link className="cursor-pointer" prefetch={false} href={link && link !== '' ? link : `/shop/${slug}?tab=${currentTab}`}>
              <span className="text-[#1A0DAB] underline cursor-pointer" > View All</span>
            </Link>
          ) : null}
        </div>
        <p>{desc}</p>
      </div>


      <div className="product-list">
        {children}
        {/* {productSize < 3 && extraDetails ? (
          <div className="category-banner-extra">
            <h4>{extraDetails?.titleText}</h4>
            <Link prefetch={false} href={link && link !== '' ? link : `/shop/${slug}?tab=${currentTab}`}>
              <button className="btn ">{extraDetails?.buttonText}</button>
            </Link>
            <div />
          </div>
        ) : null} */}
      </div>
      {/* {children} */}
    </div>
  );
};

export default SpecialCategoryLayout;